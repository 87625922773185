<template>
  <div v-if="currentSchedule" class="edit-form">
    <h4>Schedule</h4>
    <form>
      <div class="form-group">
        <label for="hour">Hora</label>
        <div style="display:flex">
        <input
          type="number"
          class="form-control"
          id="hour"
          required
          v-model="currentSchedule.hour"
          name="hour"
          style="width:9em;flex-grow:1"
        />
        <input
          type="number"
          class="form-control"
          id="minute"
          required
          v-model="currentSchedule.minute"
          name="hour"
          style="width:9em;flex-grow:1"
        />
        </div>
      </div>
      <div class="form-group">
        <label><input type="checkbox" id="day0" v-model="currentSchedule.day0" name="day0"/> Domingo</label><br/>
        <label><input type="checkbox" id="day1" v-model="currentSchedule.day1" name="day1"/> Segunda</label><br/>
        <label><input type="checkbox" id="day2" v-model="currentSchedule.day2" name="day2"/> Terça</label><br/>
        <label><input type="checkbox" id="day3" v-model="currentSchedule.day3" name="day3"/> Quarta</label><br/>
        <label><input type="checkbox" id="day4" v-model="currentSchedule.day4" name="day4"/> Quinta</label><br/>
        <label><input type="checkbox" id="day5" v-model="currentSchedule.day5" name="day5"/> Sexta</label><br/>
        <label><input type="checkbox" id="day6" v-model="currentSchedule.day6" name="day6"/> Sábado</label>
      </div>

    </form>
    <button class="badge badge-danger mr-2"
      @click="deleteSchedule"
    >
      Remover
    </button>

    <button type="submit" class="badge badge-success"
      @click="updateSchedule"
    >
      Atualizar
    </button>
    <p>{{ message }}</p>
  </div>

  <div v-else>
    <br />
    <p>Please click on a Schedule...</p>
  </div>
</template>

<script>
import ScheduleDataService from "../services/ScheduleDataService";

export default {
  name: "schedule",
  data() {
    return {
      currentSchedule: null,
      message: ''
    };
  },
  methods: {
    getSchedule(id) {
      ScheduleDataService.get(id)
        .then(response => {
          this.currentSchedule = response.data;
          console.log(response.data);
          this.currentSchedule.hour = ('00'+this.currentSchedule.hour).substr(-2);
          this.currentSchedule.minute = ('00'+this.currentSchedule.minute).substr(-2);
        })
        .catch(e => {
          console.log(e);
        });
    },

    updatePublished(status) {
      var data = {
        id: this.currentSchedule.id,
        title: this.currentSchedule.title,
        description: this.currentSchedule.description,
        published: status
      };

      ScheduleDataService.update(this.currentSchedule.id, data)
        .then(response => {
          console.log(response.data);
          this.currentSchedule.published = status;
          this.message = 'The status was updated successfully!';
        })
        .catch(e => {
          console.log(e);
        });
    },

    updateSchedule() {
      ScheduleDataService.update(this.currentSchedule.id, this.currentSchedule)
        .then(response => {
          console.log(response.data);
          this.message = 'The schedule was updated successfully!';
        })
        .catch(e => {
          console.log(e);
        });
    },

    deleteSchedule() {
      ScheduleDataService.delete(this.currentSchedule.id)
        .then(response => {
          console.log(response.data);
          this.$router.push({ name: "schedules" });
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  mounted() {
    this.message = '';
    this.getSchedule(this.$route.params.id);
  }
};
</script>

<style>
.edit-form {
  max-width: 300px;
  margin: auto;
}
</style>
